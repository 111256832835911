import NAMES from "@/config/names";
import ROLES from "../config/roles";

export const PUBLIC = {
  BASE_PATH: "/",
  PAGES: {
    LANDING: "home",
  },
};

// AUTH
export const AUTH = {
  BASE_PATH: "/auth",
  PAGES: {
    LOGIN: "login",
    FORGOT_PASSWORD: "forgot-password",
    EMAIL_SENT: "sent-email",
    RESET_PASSWORD: "reset-password",
  },
};

//PRIVATE
export const PRIVATE = {
  BASE_PATH: "/:userType",
  ADMIN: {
    ADMIN_BASE_PATH: ROLES.ADMIN,
    SUPER_ADMIN_BASE_PATH: ROLES.SUPER_ADMIN,
    PAGES: {
      INDEX: "overview",
      DASHBOARD: "overview",
      BUSINESS_UNIT: NAMES.BUSINESS_UNITS,
      BUSINESS_UNIT_SINGLE:
        NAMES.BUSINESS_UNITS + "/single-" + NAMES.BUSINESS_UNIT + "/:id",
      BUSINESS_UNIT_SINGLE_TABS: {
        BUSINESS_DETAILS: "business-details",
        OWNER_DETAILS: "owner-details",
        ADDRESS: "address",
        MOBILE_APP: "mobile-app",
        SUPPORT_DETAILS: "support-details",
        CUSTOMISATION: "customisation",
      },
      SETTINGS: "settings",
      SETTINGS_TABS: {
        PERSONAL_INFO: "personal-info",
        PLAN: "plan-billing",
        SECURITY_INFO: "security-info",
        SUPPORT_DETAILS: "support-details",
        MAIL_NOTIFICATION: "mail-notification",
        MOBILE_APP: "mobile-app",
        COUNTRY: "country",
      },
      CHANGE_PASSWORD: "settings/change-password",
    },
  },
};

export const ERROR = {
  ERROR_403: "/403",
  CATCH_ALL: "*",
};
