import { APP_NAME } from "@/constants/constants";
import LogoImage from "@assets/svg/logo.svg";

export const Logo = () => {
  return (
    <div className="flex items-center gap-2 w-40 h-8">
      <img
        src={LogoImage}
        alt={`${APP_NAME} logo`}
        className="w-8 h-full object-cover"
      />
      <p className="text-2xl font-medium">
        <span className="text-PRIMARY-DARK" >{APP_NAME?.substring(0, 3)}</span>
        <span className="text-TEXT-SECONDARY_DARK" >{APP_NAME?.substring(3)}</span>
      </p>
    </div>
  );
};
