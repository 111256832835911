import React from "react";
import LogoImage from "@assets/svg/logo.svg";
import "./index.scss";
import { Logo } from "../logo";

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <div className="app-logo">
        <Logo />
      </div>
      <div className="ball-scale-ripple-multiple">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
