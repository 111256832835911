enum API {
  LOGIN = "/auth/super-admin/login",
  TENANT = "/pm-tenant-service/tenant",
  OVER_VIEW = "/pm-tenant-service/tenant-overview",
  SETTINGS = "/pm-tenant-service/settings",
  SIGNUP = "/auth/signup",
  LOGOUT = "/auth/super-admin/logout",
  FORGOT_PASSWORD = "/auth/forgot-password",
  RESET_PASSWORD = "/auth/reset-password",
  VALIDATE_RESET_PASSWORD = "/auth/reset-password/validate",
  UPDATE_PASSWORD = "pm-tenant-service/super-admin/update-password",
  GET_SUPPORT_DETAILS = "pm-tenant-service/settings/supportdetails",
  GET_MOBLIE_APP_VIRSION = "pm-tenant-service/settings/mobileapp",
  GET_NOTIFICATION_DETAILS = "pm-tenant-service/settings/mailnotification",
  GET_COUNTRIE_DETAILS = "pm-tenant-service/settings/country",
  EDIT_SUPPORT_DETAILS = "pm-tenant-service/settings/supportdetails/update",
  EDIT_MOBILE_APP_VERSION = "pm-tenant-service/settings/mobileapp/update",
  EDIT_NOTIFICATION_DETAILS = "pm-tenant-service/settings/mailnotification/update",
  EDIT_COUNTRIES_DETAILS = "pm-tenant-service/settings/country/update",
  REFRESH_TOKEN = "/auth/token",
  PROFILE = "/user/profile",
}

const publicEndpoints = [
  API.FORGOT_PASSWORD,
  API.LOGIN,
  API.RESET_PASSWORD,
  API.LOGOUT,
  API.REFRESH_TOKEN,
  API.RESET_PASSWORD,
  API.SIGNUP,
  API.VALIDATE_RESET_PASSWORD,
  API.UPDATE_PASSWORD,
];

const privateEndpoints = [API.TENANT, API.OVER_VIEW];

export { publicEndpoints, privateEndpoints };

export default API;
