import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { PUBLIC, PRIVATE, ERROR, AUTH } from "./routes";
import { useAppSelector } from "../store/hooks";

import Loader from "../components/common/loader";
import { SettingsTabs } from "@/config/settingsNav";

const PublicLayout = lazy(() => import("../layouts/publicLayout"));
const AuthLayout = lazy(() => import("../layouts/authLayout"));
const AdminLayout = lazy(() => import("../layouts/adminLayout"));

const Landing = lazy(() => import("../pages/public/landing"));

const Login = lazy(() => import("@pages/auth/login"));
const ForgotPassword = lazy(() => import("@pages/auth/forgotPassword"));
const SentEmail = lazy(() => import("@pages/auth/sentEmail"));

const Overview = lazy(() => import("../pages/admin/dashboard"));
const BusinessUnit = lazy(() => import("../pages/admin/businessUnit"));
const SingleBusinessUnit = lazy(
  () => import("../pages/admin/singleBusinessUnit")
);
const BusinessDetails = lazy(
  () => import("../pages/admin/singleBusinessUnit/businessDetails")
);
const OwnerDetails = lazy(
  () => import("../pages/admin/singleBusinessUnit/ownerDetails")
);
const Address = lazy(() => import("../pages/admin/singleBusinessUnit/address"));
const MobileAppSingleView = lazy(
  () => import("../pages/admin/singleBusinessUnit/mobileApp")
);
const SupportDetailsSingleView = lazy(
  () => import("../pages/admin/singleBusinessUnit/supportDetails")
);
const Customisation = lazy(
  () => import("../pages/admin/singleBusinessUnit/customisation")
);

const Settings = lazy(() => import("../pages/admin/settings"));
const PersonalInfo = lazy(() => import("../pages/admin/settings/personalInfo"));
const Plan = lazy(() => import("../pages/admin/settings/plan"));
const SecurityInfo = lazy(() => import("../pages/admin/settings/securityInfo"));
const SupportDetails = lazy(
  () => import("../pages/admin/settings/supportDetails")
);
const MailNotification = lazy(
  () => import("../pages/admin/settings/mailNotification")
);
const MobileApp = lazy(() => import("../pages/admin/settings/mobileApp"));
const Country = lazy(() => import("../pages/admin/settings/country"));

const Error = lazy(() => import("../pages/common/Error"));

const MainRoute: React.FC<any> = () => {
  const { isAppInitialized } = useAppSelector((state: any) => state.user);

  if (!isAppInitialized) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Public Routes */}
        <Route path={PUBLIC.BASE_PATH} element={<PublicLayout />}>
          <Route
            path={PUBLIC.PAGES.LANDING}
            element={
              <Suspense fallback={<Loader />}>
                <Landing />
              </Suspense>
            }
          />

          <Route index element={<Navigate to={AUTH.BASE_PATH} />} />
        </Route>

        {/* Auth Routes */}
        <Route path={AUTH.BASE_PATH} element={<AuthLayout />}>
          <Route
            path={AUTH.PAGES.LOGIN}
            element={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.FORGOT_PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.EMAIL_SENT}
            element={
              <Suspense fallback={<Loader />}>
                <SentEmail />
              </Suspense>
            }
          />

          <Route index element={<Navigate to={AUTH.PAGES.LOGIN} />} />
        </Route>

        {/* Super Admin Routes */}
        <Route
          path={PRIVATE.ADMIN.SUPER_ADMIN_BASE_PATH}
          element={<AdminLayout isSuperAdmin />}
        >
          <Route
            path={PRIVATE.ADMIN.PAGES.DASHBOARD}
            element={
              <Suspense fallback={<Loader />}>
                <Overview />
              </Suspense>
            }
          />
          {/* <Route
            path={`overview/single-Client/:id`}
            element={
              <Suspense fallback={<Loader />}>
                <SingleBusinessUnit />
              </Suspense>
            }
          /> */}
          <Route
            path={PRIVATE.ADMIN.PAGES.BUSINESS_UNIT}
            element={
              <Suspense fallback={<Loader />}>
                <BusinessUnit />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE}
            element={
              <Suspense fallback={<Loader />}>
                <SingleBusinessUnit />
              </Suspense>
            }
          >
            <Route
              path={
                PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE_TABS.BUSINESS_DETAILS
              }
              element={
                <Suspense fallback={<Loader />}>
                  <BusinessDetails />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE_TABS.OWNER_DETAILS}
              element={
                <Suspense fallback={<Loader />}>
                  <OwnerDetails />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE_TABS.ADDRESS}
              element={
                <Suspense fallback={<Loader />}>
                  <Address />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE_TABS.MOBILE_APP}
              element={
                <Suspense fallback={<Loader />}>
                  <MobileAppSingleView />
                </Suspense>
              }
            />
            <Route
              path={
                PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE_TABS.SUPPORT_DETAILS
              }
              element={
                <Suspense fallback={<Loader />}>
                  <SupportDetailsSingleView />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE_TABS.CUSTOMISATION}
              element={
                <Suspense fallback={<Loader />}>
                  <Customisation />
                </Suspense>
              }
            />

            <Route
              index
              element={
                <Navigate
                  to={
                    PRIVATE.ADMIN.PAGES.BUSINESS_UNIT_SINGLE_TABS
                      .BUSINESS_DETAILS
                  }
                />
              }
            />
          </Route>
          <Route
            path={`${PRIVATE.ADMIN.PAGES.SETTINGS}`}
            element={
              <Suspense fallback={<Loader />}>
                <Settings />
              </Suspense>
            }
          >
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.PERSONAL_INFO}
              element={
                <Suspense fallback={<Loader />}>
                  <PersonalInfo />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.PLAN}
              element={
                <Suspense fallback={<Loader />}>
                  <Plan />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.SECURITY_INFO}
              element={
                <Suspense fallback={<Loader />}>
                  <SecurityInfo />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.SUPPORT_DETAILS}
              element={
                <Suspense fallback={<Loader />}>
                  <SupportDetails />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.MAIL_NOTIFICATION}
              element={
                <Suspense fallback={<Loader />}>
                  <MailNotification />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.MOBILE_APP}
              element={
                <Suspense fallback={<Loader />}>
                  <MobileApp />
                </Suspense>
              }
            />
            <Route
              path={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.COUNTRY}
              element={
                <Suspense fallback={<Loader />}>
                  <Country />
                </Suspense>
              }
            />
            <Route
              index
              element={
                <Navigate
                  to={PRIVATE.ADMIN.PAGES.SETTINGS_TABS.PERSONAL_INFO}
                />
              }
            />
          </Route>
          <Route
            path={ERROR.CATCH_ALL}
            element={
              <Suspense fallback={<Loader />}>
                <Error type={404} />
              </Suspense>
            }
          />
          <Route index element={<Navigate to={PRIVATE.ADMIN.PAGES.INDEX} />} />
        </Route>

        {/* Other Routes */}
        <Route
          path={ERROR.CATCH_ALL}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={404} />
            </Suspense>
          }
        />
        <Route
          path={ERROR.ERROR_403}
          element={
            <Suspense fallback={<Loader />}>
              <Error type={403} />
            </Suspense>
          }
        />
        <Route index element={<Navigate to={PUBLIC.PAGES.LANDING} />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
