import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const getDeviceId = async () => {
  const fpPromise = FingerprintJS.load();

  try {
    const fp = await fpPromise;
    const result = await fp.get();
    if (await result.visitorId) {
      return result.visitorId;
    }
  } catch (error) {
    alert(error);
  }
  // Get the visitor identifier when you need it.
};

export const setInputFilter = (value: any) => {
  return /^\d*\.?\d*$/.test(value);
};

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const truncate = (input: string, length?: number) =>
  input.length > 5 ? `${input.substring(0, length || 5)}...` : input;

//show/ hide dropdown list
export const toggleList = (id: string): void => {
  const element = document.getElementById(id);
  if (element !== null) {
    if (element?.classList.contains("hidden"))
      element.classList.remove("hidden");
    else element.classList.add("hidden");
  }
};

//close dropdown/ select option lists while clicking outside of the component
export const handleOutsideClick =
  (containerId: string, listId: string) =>
  (event: MouseEvent): void => {
    const containerElement = document.getElementById(containerId);
    const elementList = document.getElementById(listId);
    if (
      containerElement &&
      elementList &&
      !containerElement.contains(event.target as Node)
    ) {
      elementList.classList.add("hidden");
    }
  };

export const formatDate = (date: string) => {
  const inputDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };
  const formattedDate = inputDate.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const isEsNotEmptyArray = (arr: any) =>
  Array.isArray(arr) && arr.length > 0 ? true : false;
